import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import {registerForm} from "../../redux/authActions";
import {connect} from "react-redux";
import {loadFaq} from "../../redux/helpReducer";
import {withSnackbar} from "notistack";
import PromptSignIn from "../../core/prompts/PromptSignIn";
import {getStartupParam} from "../../Util/WindowUtils";
import {ReactComponent as PlayYtIcon} from "../../images/youtube.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

class Homepage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showSubscriber: (!this.props.me.profile && localStorage.getItem('showSubscriber') !== 'false') ? true : false,
            subscriber: '',
            thankyou: ''
        };
    }

    sendSubscription() {
        let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!this.state.subscriber || !re.test(String(this.state.subscriber).toLowerCase())) {
            return alert('Invalid email');
        }
        this.props.dispatch(registerForm(this.state.subscriber, '', '59', null));
    }

    componentDidUpdate(prevProps) {
        console.log('Home change', prevProps, this.props);
        if (!prevProps.me.profile && this.props.me.profile) {
            this.setState({thankyou: 'Thank you for your participation! Join any of our existing Clubs and check your email for more updates soon'});
            console.log('Home Thank you');
        }
    }

    hideSubscriber() {
        this.setState({showSubscriber: false});
        localStorage.setItem('showSubscriber', false);
    }

    handleFaq(evt, nid) {
        evt.preventDefault();
        loadFaq(nid, 'dialog');
        return false;
    }


    checkAuthorized(e, url) {
        e.preventDefault();
        if (!this.props.me.profile) {
            this.props.enqueueSnackbar('signin', {
                variant: 'warning',
                content: <PromptSignIn onClose={() => this.props.closeSnackbar('signin-to-order')}/>,
                key: 'signin-to-order',
                anchorOrigin: {vertical: 'top', horizontal: 'right'},
            });
            return false;

        }
        this.props.history.push(url);
    }

    buildButton(action, gid) {
        // start a club
        // search clubs
        // search matches (!mvp)
        // organize a match (!pmvp)
        const props = {
            component: Link, variant: 'contained',
            className: this.props.classes.twoLineButton, dense: true,
            style: {width: 200}
        }
        if (action === 'subgroups') {
            return <Button {...props} color={'secondary'}
                           to={`/group/${gid}/groups`}>
                                    <span>
                                        <span>Search Clubs</span>
                                        <span className={'caption'}>MeetUp Groups to Leagues</span>
                                    </span>
            </Button>
        } else if (action === 'startclub') {
            return <Button {...props} color={'secondary'}
                           to={'/group/start?game_type=869'}
                           onClick={(e) => this.checkAuthorized(e, '/group/start?game_type=869')}>
                                    <span>
                                        <span>Start a Club</span>
                                        <span className={'caption'}>Your privacy and payout policies</span>
                                    </span>
            </Button>
        } else if (action === 'matches') {
            props.onClick = (e) => this.props.history.push(`/group/${gid}/search?types[]=rewards`)

            return <Button {...props} color={'secondary'}>
                                    <span>
                                        <span>Search Matches</span>
                                        <span className={'caption'}>Any Sport</span>
                                    </span>
            </Button>
        } else if (action === 'organize') {
            if (gid === 59) {
                props.onClick = () => this.props.enqueueSnackbar('First select a Club to post a Match')
            } else {
                props.onClick = (e) => this.checkAuthorized(e, `/forms/group/${gid}/rewards/add?bundle=pickup_match`)
                props.to = `/forms/group/${gid}/rewards/add?bundle=pickup_match`
            }

            return <Button {...props} color={'secondary'}>
                                <span>
                                <span>Organize a Match</span>
                                <span className={'caption'}>or tournament, your way</span>
                                </span>
            </Button>
        }
    }

    render() {

        const {classes} = this.props;

        let dims = {w: 375, h: 812}; // iphone X
        dims.h = Math.min(812, Math.floor(window.innerHeight / 1.2));
        let ratio = dims.h / 812;
        dims.w = Math.floor(dims.w * ratio);
        let vidPath = '/videos/gid_61/mobile/kauai.mp4';
        vidPath = 'https://trackauthoritymusic.com' + vidPath;
        let gid = 59;
        if (document.location.pathname === '/kauai') {
            gid = 61;
        } else if (document.location.pathname === '/bayarea') {
            gid = 60;
        } else if (document.location.pathname === '/sport12') {
            gid = 56;
        }

        return (<React.Fragment>
                <div className={classes.panBG}>
                    <div className={'img'}></div>
                </div>
                <Grid container direction="row" justifyContent="center" alignItems="center" alignContent={'center'}
                      style={{gap: 0, textAlign:'center'}}>
                    <Grid container direction='column' alignItems='center' item xs={12} sm={8}>
                        <Grid item>
                            <img width={300}
                                 src={`${process.env.REACT_APP_API_URL}/sites/default/files/groups/covers/2024-02/pickupmvp-logo.png`}
                                 alt="Pickup MVP"/>
                        </Grid>

                        <Grid item >
                            <Typography variant='h2'>Play / Record &gt; Highlight / Reward</Typography>
                            <Typography variant='h5'>Any Sport or Match Up</Typography>
                            <br/>

                            {(!getStartupParam('appOS').length) &&
                                <div style={{margin:'20px 0'}}>
                                    <a href={'https://apps.apple.com/app/pickupmvp/id6447146796'} target={"_blank"}
                                       style={{marginRight: 20}}>
                                        <img src={'/assets/appstore.png'} height={50}/>
                                    </a>
                                    <a href={'https://play.google.com/store/apps/details?id=com.pickupmvp'}
                                       target={"_blank"}
                                    >
                                        <img src={'/assets/googleplay.png'} height={50}/>
                                    </a>
                                </div>
                            }

                            <Grid item xs={12} >
                                <Typography variant={'h4'}><strong style={{fontWeight: 600}}>Free & Friendly
                                    Pickup</strong> / <strong style={{fontWeight: 600}}>Cash Purse
                                    Tournaments</strong></Typography>
                                <Typography variant={'h4'}>Earn $ for Organizing, Recording, or MVP ranking in any
                                    category</Typography>
                            </Grid>

                        </Grid>

                        <Grid container direction='row' alignItems='center' justifyContent='space-around'
                              spacing={2} style={{margin:'40px 0'}}>
                            <React.Fragment>
                                <Grid item xs={6} lg={3}>
                                    {this.buildButton('matches', gid)}
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    {this.buildButton('subgroups', gid)}
                                </Grid>
                            </React.Fragment>

                            {gid == 59 &&
                                <React.Fragment>
                                    <React.Fragment>
                                        <Grid item xs={6} lg={3}>
                                            {this.buildButton('organize', gid)}
                                        </Grid>
                                        <Grid item xs={6} lg={3}>
                                            {this.buildButton('startclub', gid)}
                                        </Grid>
                                    </React.Fragment>
                                </React.Fragment>
                            }
                        </Grid>

                        <Grid item xs={12} style={{marginTop: 40}}>

                            <a href={'https://www.youtube.com/channel/UCbrvjkymhOsWk90CAXJYi_g'}
                               target={"_blank"}>
                            <Typography variant={'h2'} style={{display:'flex', alignItems:'center'}} >
                                <SvgIcon component={PlayYtIcon} htmlColor={"#FF0000"} fontSize={'large'} style={{marginRight:5}} />
                                Youtube Channel
                            </Typography>
                            </a>

                            <Typography variant={'h2'}>
                                <small>415-300-0834</small>
                            </Typography>
                            <Typography variant={'h2'}>
                                <small>staff@</small><strong>PickupMVP</strong><em>.com</em>
                            </Typography>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} sm={4} style={{width: dims.w, height: dims.h, paddingTop: 40}}>
                        <div className="mobile" style={{width: dims.w, height: dims.h}}>
                            <div className="deviceMask">
                                <video
                                    style={{objectFit: 'cover'}}
                                    width={'100%'} height={'100%'}
                                    autoPlay muted playsInline loop={true}
                                    src={vidPath} type="video/mp4"
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
            ;
    }

}

const styles = theme => ({
    logo: {
        width: '80%',
        maxWidth: 300,
        margin: '0 auto'
    },
    vidBackground: {
        zIndex: -1,
        position: 'fixed',
        right: 0,
        left: 0,
        top: 0,
        opacity: .2,
        minWidth: '100%',
        minHeight: '100%'
    },
    panBG: {
        zIndex: -1,
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        height: '80%',
        opacity: .17,
        '& .img': {
            animation: 'panBG 1 100s linear',
            backgroundImage: `url(/assets/allathletes-bg.png)`,
            backgroundRepeat: 'repeat-x',
            backgroundSize: 'cover',
            minWidth: '100%',
            height: '100%',
            position: 'absolute'
        }
    },
    card: {
        width: '100%',
        maxWidth: 960,
        justifyContent: 'center',
        marginBottom: 15,
    },
    list: {
        marginTop: 10,
        marginBottom: 10,
    },
    paperBg: {
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: 8
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    content: {
        padding: theme.spacing(1)
    },
    actions: {
        padding: '3px 6px 3px 6px',
        margin: '10px auto 10px auto',
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between'
    },
    twoLineButton: {
        fontSize: '150%',
        textAlign: 'left',
        lineHeight: 1,
        '& > span': {
            textAlign: 'left',
            lineHeight: 1
        },
        '& .caption': {
            fontSize: 11,
            display: 'block',
            fontWeight: 900,
            margin: 0,
            lineHeight: 1
        }
    }
});

Homepage.defaultProps = {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
};

Homepage.propTypes = {
    windowHeight: PropTypes.number,
    windowWidth: PropTypes.number
};

const mapStateToProps = (state) => {
    return state.auth;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme:true})(withRouter(withSnackbar(Homepage))));
